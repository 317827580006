import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  useTheme,
  Autocomplete,
  createFilterOptions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { MultiSelector } from '@zippeditoolsjs/blocks';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  limit: 200,
});

export default function ClientCreation(props) {
  const [permissions, setPermissions] = useState([]);
  const [chain, setChain] = useState([]);
  const [supplier, setSupplier] = useState(null);
  const [role, setRole] = useState(null);
  const [stores, setStores] = useState([]);
  const [feature, setFeature] = useState([]);
  const [category, setCategory] = useState([]);
  const [position, setPosition] = useState(0);
  const [categoriesList, setCategoriesList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [client, setClient] = useState([]);
  const [family, setFamily] = useState(10);

  const [tempChain, setTempChain] = useState([]);
  const [tempStores, setTempStores] = useState([]);
  const [tempIndex, setTempIndex] = useState(null);
  const [tempClient, setTempClient] = useState([]);
  const [tempRole, setTempRole] = useState(null);
  const [tempCategory, setTempCategory] = useState([]);
  const [tempSupplier, setTempSupplier] = useState(null);
  const [tempFeature, setTempFeature] = useState([]);
  const [tempFamily, setTempFamily] = useState(10);

  const { t } = useTranslation();
  const theme = useTheme();
  const {
    currentStep,
    chainsList,
    storesList,
    setSelectedChains,
    selectedChains,
    activeApps,
    setActiveApps,
    apps,
    suppliers,
    getSuppliers,
    user,
    categories,
    getCategories,
    isLoadingCategories,
    getClients,
    clients,
    isLoadingClients,
    getLocations,
    isLoadingChains,
    setRequireProfile,
  } = props;

  const appName = currentStep;


  const styles = {
    iconComponent: {
      color:
        theme.palette.type === 'dark' ? 'white' : theme.palette.primary.main,
    },
    iconComponentDisabled: {
      color: theme.palette.type === 'dark' ? 'white' : theme.palette.grey[400],
    },
    iconButton: {
      ':hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? `${theme.palette.primary.main}33`
            : `{theme.palette.grey[400]}33`,
      },
    },
  };

  const handlePermSelection = (event, name) => {
    const { checked } = event.target;
    const newPerms = permissions.map(perm =>
      perm.name === name ? { ...perm, selected: checked } : perm,
    );
    setPermissions(newPerms);
    setActiveApps(
      activeApps.map(app =>
        app.app_name === appName ? { ...app, permissions: newPerms, modified: true } : app,
      ),
    );
  };

  const handleScopesSelection = () => {

    let payload = activeApps.find(app => app.app_name === appName)?.payload?.slice();
    if (payload === undefined) {
      payload = []
    }

    if (clientList.find(client => client.name === client)?.client_id) {
      (family !== 11) ? setFamily(10) : setFamily(family)
    } else if (clientList.find(client => client.name === client)?.client_id === undefined && clientList.find(client => client.name === client)?.supplier_id === undefined) {
      setFamily(11)
    }

    const scope = {
      client: client ? client.map(client => client.name) : [],
      chain,
      store: stores,
      supplier,
      role: role,
      feature,
      category,
      family,
    }

    payload.forEach((scope, index) => {
      if (scope.family) {
        payload[index].family = family;
      }
    });

    payload.push(scope);
    setPosition(position + 1);

    setActiveApps(
      activeApps.map(app =>
        app.app_name === appName ? { ...app, payload: payload, modified: true } : app,
      ),
    );
    const currentChains = selectedChains.slice();
    if (!currentChains?.some(chain => chain.chain_name === chain)) {
      chain.forEach(chain => {
        currentChains.push({ chain_name: chain.chain_name });
      });
      setSelectedChains(currentChains);
    }
    restoreDefault();
  };

  const restoreTemp = (index) => {
    if (index !== undefined) {
      let payload = activeApps.find(app => app.app_name === appName)?.payload
      payload[index].chain = tempChain;
      payload[index].store = tempStores;
      payload[index].client = tempClient.map(client => client.name);
      payload[index].role = tempRole;
      payload[index].supplier = tempSupplier;
      payload[index].feature = tempFeature;
      payload[index].category = tempCategory;
      payload[index].family = tempFamily;
    }

    setTempChain([]);
    setTempStores([]);
    setTempIndex(null);
    setTempClient([]);
    setTempRole(null);
    setTempCategory([]);
    setTempSupplier(null);
    setTempFeature([]);
    setTempFamily(10);

  };

  const restoreDefault = () => {
    setChain([]);
    setStores([]);
    setSupplier(null);
    setRole(null);
    setFeature([]);
    setCategory([]);
    setCategoriesList([]);
    setClient([]);
    getLocations(user.token);
    restoreTemp();

  };

  const handleUpdateRow = async (index) => {

    const payload = activeApps.find(app => app.app_name === appName)?.payload?.slice();
    setActiveApps(
      activeApps.map(app =>
        app.app_name === appName ? { ...app, payload: payload, modified: true } : app,
      ),
    );
    setTempIndex(index);

    if (payload[index].chain) {
      if (Array.isArray(payload[index].chain)) {
        setTempChain(payload[index].chain);
      } else {
        setTempChain([chainsList.find(chain => chain.chain_name === payload[index].chain)]);
      }
    }
    let store_id = null;
    if (payload[index].store) {
      if (Array.isArray(payload[index].store)) {
        setTempStores(payload[index].store);
        store_id = payload[index].store.map(store => store.store_id);
      } else {
        setTempStores([storesList.find(store => store.store_code === payload[index].store)]);
        store_id = storesList.find(store => store.store_code === payload[index].store).store_id;
      }
    }
    if (payload[index].client) {
      setTempClient(clientList.filter(client => payload[index].client.includes(client.name)));
    }
    if (payload[index].role) {
      setTempRole(payload[index].role);
    }
    if (payload[index].category) {
      if (Array.isArray(payload[index].category)) {
        setTempCategory(payload[index].category);
      } else {
        await getCategories(user.token, store_id).then(() => {
          setTempCategory([categoriesList.find(category => category.value === payload[index].category)]);
        });
      }
    }
    if (payload[index].supplier) {
      setTempSupplier(payload[index].supplier);
    }
    if (payload[index].feature) {
      if (Array.isArray(payload[index].feature)) {
        setTempFeature(payload[index].feature);
      } else {
        setTempFeature(apps?.filter(app => app.app_name === appName)[0]?.features?.filter(feature => payload[index].feature.includes(feature.name)));
      }
    }
    if (payload[index].family) {
      setTempFamily(payload[index].family);
    }

  };

  const handleScopeRemoval = (index) => {
    const payload = activeApps.find(app => app.app_name === appName)?.payload?.slice();

    //remove chain from selected chains
    const currentChains = selectedChains.slice();

    if (Array.isArray(payload[index].chain)) {
      payload[index].chain.forEach(chain => {
        const chainIndex = currentChains.findIndex(
          currentChain => currentChain.chain_name === chain.chain_name,
        );
        if (chainIndex !== -1) {
          currentChains.splice(chainIndex, 1);
          setSelectedChains(currentChains);
        }
      });
    } else {
      const chainIndex = currentChains.findIndex(
        currentChain => currentChain.chain_name === payload[index].chain,
      );
      if (chainIndex !== -1) {

        let found = false;
        payload.forEach((scope, index) => {
          if (chainIndex !== index && scope.chain === payload[index].chain) {
            found = true;
          }
        });
        if (!found) {

          currentChains.splice(chainIndex, 1);
          setSelectedChains(currentChains);
        }
      }
    }

    payload.splice(index, 1);
    setActiveApps(
      activeApps.map(app =>
        app.app_name === appName ? { ...app, payload: payload, modified: true } : app,
      ),
    );
    restoreDefault();

  };

  const handleDisableButton = () => {
    const activeApp = activeApps.find(app => app.app_name === appName);
    const multiple_roles = activeApp.multiple_roles;
    const requirements = activeApp.requirements;
    const payload = activeApp.payload;
    let disabled = false;

    if (payload?.length > 0 && !multiple_roles) {
      disabled = true;
    }

    if (requirements?.length > 0) {
      requirements.forEach(req => {
        switch (req) {
          case 'clients':
            if (!client.length > 0) {
              disabled = true;
            }
            break;
          case 'chains':
            if (!chain.length > 0) {
              disabled = true;
            }
            break;
          case 'stores':
            if (!stores.length > 0) {
              disabled = true;
            }
            break;
          case 'suppliers':
            if (!supplier) {
              disabled = true;
            }
            break;
          case 'roles':
            if (!role) {
              disabled = true;
            }
            break;
          case 'features':
            if (!feature.length > 0) {
              disabled = true;
            }
            break;
          case 'categories':
            if (!category.length > 0) {
              disabled = true;
            }
            break;
          default:
            break;
        }
      });
    }
    return disabled;
  };

  const handleRequired = (requirement) => {
    return apps?.filter(app => app.app_name === appName)[0]?.requirements?.some(
      req => req === requirement,
    )
  }
  const containsScope = (scope) => {

    return apps?.filter(app => app.app_name === appName)[0]?.scopes?.some(
      req => req === scope,
    )
  }

  useEffect(() => {
    setPermissions(activeApps.find(app => app.app_name === appName).permissions);
  }, [appName]);


  useEffect(() => {
    if (!suppliers && !clients && user) {
      getSuppliers(user.token);
      getClients(user.token);
    }
  }, [suppliers]);

  useEffect(() => {
    if (activeApps.find(app => app.app_name === appName)?.payload?.length > 0) {
      setPosition(activeApps.find(app => app.app_name === appName)?.payload?.length);
    }
  }, []);

  useEffect(() => {
    restoreDefault();
  }, [appName]);
  useEffect(() => {
    if (categories?.categories?.length > 0) {
      let parsedCategories = categories.categories.map((category) => ({ ...category, parsed_name: `(${category.value}) ${category.category_name}` }));
      parsedCategories = [{ value: null, category_name: 'All', parsed_name: 'All' }, ...parsedCategories.splice(1)];

      setCategoriesList(parsedCategories);
    } else {
      setCategoriesList([]);
    }
  }, [categories]);

  useEffect(() => {
    if (stores.length > 0) {
      getCategories(user.token, stores[0].store_id);
    }
  }
    , [stores]);


  useEffect(() => {
    if (clients?.length > 0) {
      setClientList(clients.filter(client => client.is_active === true));
    } else {
      setClientList([]);
    }
  }, [clients, tempClient]);


  useEffect(() => {
    setStores([]);
    setCategory([]);
  }, [chain]);

  useEffect(() => {
    if (client?.length > 0 && tempChain?.length === 0) {
      getLocations(user.token, client?.map(client => client.client_id));
    }
    else if (tempClient?.length > 0 && client?.length === 0) {
      getLocations(user.token, tempClient?.map(client => client.client_id));
    }
  }, [client, tempClient]);

  useEffect(() => {
    if (activeApps.find(app => app.app_name === appName)?.require_profile && activeApps.find(app => app.app_name === appName)?.payload?.length === 0) {
      setRequireProfile(true);
    } else {
      setRequireProfile(false);
    }
  }, [activeApps, appName]);



  const parseStores = (store) => {
    try {
      return store.map(store => store.store_code).join(', ')
    } catch (error) {
      return store
    }
  }

  const parseCategories = (category) => {
    try {
      return category.map(category => category.category_name).join(', ')
    } catch (error) {
      return category
    }
  }
  return (
    <Grid container spacing={2}>
      {permissions.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" textAlign="left">
            {t('ciam_app.Home.permissions')}
          </Typography>
        </Grid>
      )}
      {permissions.map((perm, index) => (
        <Grid item xs={4} key={index} display="flex">
          <FormControlLabel
            control={
              <Checkbox
                checked={perm.selected}
                onChange={event => handlePermSelection(event, perm.name)}
                name={perm.name}
              />
            }
            label={perm.name}
          />
        </Grid>
      ))}
      {(apps?.filter(app => app.app_name === appName)[0]?.scopes?.length > 0 || apps?.filter(app => app.app_name === appName)[0]?.rol_per_scope?.length > 0) && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1" textAlign="left" paddingBottom={2}>
              {t('ciam_app.Home.scopes')}
            </Typography>
          </Grid>
          <TableContainer>
            <Table aria-label="clients table" size="small">
              <TableHead>
                <TableRow>
                  {containsScope('clients') && <TableCell>{t('ciam_app.Home.clients')}</TableCell>}
                  {containsScope('chains') && <TableCell>{t('ciam_app.Home.chains')}</TableCell>}
                  {containsScope('stores') && <TableCell>{t('ciam_app.Home.stores')}</TableCell>}
                  {containsScope('suppliers') && <TableCell>{t('ciam_app.Home.suppliers')}</TableCell>}
                  {containsScope('categories') && <TableCell>{t('ciam_app.Home.categories')}</TableCell>}
                  {apps?.filter(app => app.app_name === appName)[0]?.rol_per_scope?.length > 0 && <TableCell>{t('ciam_app.Home.roles')}</TableCell>}
                  {apps?.filter(app => app.app_name === appName)[0]?.features?.length > 0 && <TableCell>{t('ciam_app.Home.features')}</TableCell>}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  activeApps.find(app => app.app_name === appName)?.payload?.map((scope, index) => (
                    <TableRow key={index}>
                      {containsScope('clients') && tempIndex !== index && <TableCell>
                        <Typography variant="subtitle1" textAlign="left">
                          {scope.client?.map(client => client).join(', ')}
                        </Typography>
                      </TableCell>}
                      {tempIndex === index && containsScope('clients') && tempClient && <TableCell>
                        <MultiSelector
                          label={t('ciam_app.Home.clients', 'Client')}
                          name="name"
                          value={tempClient}
                          setValue={setTempClient}
                          options={clientList}
                          loading={isLoadingClients}
                          disabled={clientList?.length === 0 || !apps.filter(app => app.app_name === appName)[0]?.scopes?.some(
                            req => req === 'clients',
                          )}
                          required={handleRequired('clients')}
                        />
                      </TableCell>
                      }

                      {containsScope('chains') && tempIndex !== index && <TableCell>
                        <Typography variant="subtitle1" textAlign="left">
                          {Array.isArray(scope.chain) ? scope.chain.map(chain => chain.chain_name).join(', ') : scope.chain}
                        </Typography>
                      </TableCell>}
                      {tempIndex === index && containsScope('chains') && tempChain && !apps?.filter(app => app.app_name === appName)[0]?.multiple_chains && <TableCell>
                        <Autocomplete
                          disableClearable
                          filterOptions={filterOptions}
                          id="chain"
                          disabled={!apps?.filter(app => app.app_name === appName)[0]?.scopes?.some(
                            req => req === 'chains',
                          )}
                          value={tempChain?.[0]?.chain_name}
                          options={chainsList?.map(option => option.chain_name)}
                          fullWidth
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={t('ciam_app.Home.chains')}
                              required={handleRequired('chains')}
                              variant="standard"
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                          onChange={(event, newValue) => {
                            setTempChain([chainsList.find(chain => chain.chain_name === newValue)]);
                            setTempStores([]);
                            setTempCategory([]);
                          }}
                        />
                      </TableCell>
                      }
                      {tempIndex === index && containsScope('chains') && tempChain && apps?.filter(app => app.app_name === appName)[0]?.multiple_chains && <TableCell>
                        <MultiSelector
                          label={t('ciam_app.Home.chains', 'Chains')}
                          name="chain_name"
                          value={tempChain}
                          setValue={setTempChain}
                          options={chainsList}
                          loading={isLoadingChains}
                          disabled={!apps?.filter(app => app.app_name === appName)[0]?.scopes?.some(
                            req => req === 'chains',
                          ) || isLoadingChains || chainsList?.length === 0}
                          required={handleRequired('chains')}
                        />
                      </TableCell>
                      }
                      {containsScope('stores') && tempIndex !== index && <TableCell>
                        <Typography variant="subtitle1" textAlign="left">
                          {parseStores(scope.store)}
                        </Typography>
                      </TableCell>}
                      {tempIndex === index && containsScope('stores') && tempStores && <TableCell>
                        <MultiSelector
                          label={t('ciam_app.Home.stores', 'Stores')}
                          name="parsed_name"
                          value={tempStores}
                          setValue={setTempStores}
                          options={storesList.filter(store =>
                            tempChain?.some(chainObj => chainObj.chain_name === store.chain_name)
                          )}
                          loading={isLoadingChains}
                          disabled={tempChain === null || !apps.filter(app => app.app_name === appName)[0]?.scopes?.some(
                            req => req === 'stores',
                          ) || isLoadingChains || storesList?.length === 0 || tempChain?.length === 0}
                          required={handleRequired('stores')}
                        />
                      </TableCell>
                      }
                      {containsScope('suppliers') && tempIndex !== index && <TableCell>
                        <Typography variant="subtitle1" textAlign="left">
                          {suppliers?.find(supplier => supplier.id === scope.supplier)?.name}
                        </Typography>
                      </TableCell>}
                      {tempIndex === index && containsScope('suppliers') && <TableCell>
                        <FormControl fullWidth>
                          <Autocomplete
                            filterOptions={filterOptions}
                            id="supplier"
                            disabled={!apps?.filter(app => app.app_name === appName)[0]?.scopes?.some(
                              req => req === 'suppliers',
                            ) || suppliers?.filter(supplier => tempChain?.some(chainObj => chainObj.chain_name === supplier.chain_list_str))?.length === 0}
                            value={tempSupplier}
                            options={suppliers?.filter(supplier => tempChain?.some(chainObj => chainObj.chain_name === supplier.chain_list_str))?.map(option => option.id)}
                            getOptionLabel={option =>
                              suppliers?.find(supplier => supplier.id === option)
                                ?.name
                            }
                            fullWidth
                            renderInput={params => (
                              <TextField
                                required={handleRequired('suppliers')}
                                {...params}
                                label={t('ciam_app.Home.supplier')}
                                variant="standard"
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            )}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'reset') {
                                setTempSupplier(null);
                              }
                              setTempSupplier(newValue);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      }
                      {containsScope('categories') && tempIndex !== index && <TableCell>
                        <Typography variant="subtitle1" textAlign="left">
                          {parseCategories(scope.category)}
                        </Typography>
                      </TableCell>}
                      {tempIndex === index && containsScope('categories') && tempCategory && <TableCell>
                        <MultiSelector
                          label={t('ciam_app.Home.category', 'Category')}
                          name="parsed_name"
                          value={tempCategory[0] ? tempCategory : []}
                          setValue={setTempCategory}
                          options={categoriesList}
                          loading={isLoadingCategories}
                          disabled={tempStores.length === 0 || categories?.categories?.length === 0 || isLoadingCategories || !apps.filter(app => app.app_name === appName)[0]?.scopes?.some(
                            req => req === 'categories',
                          )}
                          required={handleRequired('categories')}
                        />
                      </TableCell>
                      }

                      {apps?.filter(app => app.app_name === appName)[0]?.rol_per_scope?.length > 0 && tempIndex !== index && <TableCell>
                        <Typography variant="subtitle1" textAlign="left">
                          {scope.role}
                        </Typography>
                      </TableCell>}
                      {apps?.filter(app => app.app_name === appName)[0]?.rol_per_scope?.length > 0 && tempIndex === index && <TableCell>
                        <FormControl fullWidth>
                          <Autocomplete
                            filterOptions={filterOptions}
                            id="role"
                            disabled={apps?.filter(app => app.app_name === appName)[0]?.rol_per_scope?.length === 0}
                            value={tempRole}
                            options={apps?.filter(app => app.app_name === appName)[0]?.rol_per_scope.map(option => option)}
                            getOptionLabel={option => option}
                            fullWidth
                            renderInput={params => (
                              <TextField
                                required={handleRequired('roles')}
                                {...params}
                                label={t('ciam_app.Home.role')}
                                variant="standard"
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            )}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'reset') {
                                setTempRole(null);
                              }
                              setTempRole(newValue);

                            }}
                          />
                        </FormControl>
                      </TableCell>
                      }

                      {apps?.filter(app => app.app_name === appName)[0]?.features?.length > 0 && tempIndex !== index && <TableCell>
                        <Typography variant="subtitle1" textAlign="left">
                          {Array.isArray(scope.feature) ? scope.feature.map(feature => feature.name).join(', ') : scope.feature}
                        </Typography>
                      </TableCell>
                      }
                      {apps?.filter(app => app.app_name === appName)[0]?.features?.length > 0 && tempIndex === index && tempFeature && <TableCell>
                        <MultiSelector
                          label={t('ciam_app.Home.feature', 'Feature')}
                          name="name"
                          value={tempFeature}
                          setValue={setTempFeature}
                          options={apps?.filter(app => app.app_name === appName)[0]?.features}
                          disabled={apps?.filter(app => app.app_name === appName)[0]?.features?.length === 0}
                          required={handleRequired('features')}
                        />
                      </TableCell>
                      }
                      <TableCell>
                        <IconButton
                          disabled={isLoadingCategories}
                          sx={styles.iconButton} onClick={() =>
                            tempIndex === index ? restoreTemp(index) : handleUpdateRow(index)
                          }>
                          <IconComponent
                            iconName={tempIndex === index ? 'checkmark-outline' : 'create-outline'}
                            className={'icon'}
                            style={styles.iconComponent}
                          />
                        </IconButton>
                        <IconButton
                          disabled={isLoadingCategories}
                          sx={styles.iconButton} onClick={() => handleScopeRemoval(index)}>
                          <IconComponent
                            iconName={'trash-outline'}
                            className={'icon'}
                            style={styles.iconComponent}
                          />
                        </IconButton>

                      </TableCell>
                    </TableRow>
                  ))
                }
                <TableRow onClick={() => restoreTemp(tempIndex)}>
                  {containsScope('clients') && <TableCell sx={{ width: "20vw" }}>
                    <MultiSelector
                      label={t('ciam_app.Home.clients', 'Client')}
                      name="name"
                      value={client}
                      setValue={setClient}
                      options={clientList}
                      loading={isLoadingClients}
                      disabled={clientList?.length === 0 || !apps.filter(app => app.app_name === appName)[0]?.scopes?.some(
                        req => req === 'clients',
                      )}
                      required={handleRequired('clients')}
                    />
                  </TableCell>}
                  {containsScope('chains') && <TableCell sx={{ width: "20vw" }}>
                    <FormControl fullWidth >
                      {apps?.filter(app => app.app_name === appName)[0]?.multiple_chains ? (
                        <MultiSelector
                          label={t('ciam_app.Home.chains', 'Chains')}
                          name="chain_name"
                          value={chain}
                          setValue={setChain}
                          options={chainsList}
                          loading={isLoadingChains}
                          disabled={!apps?.filter(app => app.app_name === appName)[0]?.scopes?.some(
                            req => req === 'chains',
                          ) || isLoadingChains || chainsList?.length === 0}
                          required={handleRequired('chains')}
                        />
                      ) : (
                        <FormControl fullWidth >
                          <Autocomplete
                            filterOptions={filterOptions}
                            id="chain"
                            disabled={!apps?.filter(app => app.app_name === appName)[0]?.scopes?.some(
                              req => req === 'chains',
                            )}
                            value={chain?.[0]?.chain_name}
                            options={chainsList?.map(option => option.chain_name)}
                            fullWidth
                            renderInput={params => (
                              <TextField
                                {...params}
                                label={t('ciam_app.Home.chains')}
                                required={handleRequired('chains')}
                                variant="standard"
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            )}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'reset') {
                                setChain([]);
                                setStores([]);
                                setCategory([]);
                              }
                              setChain([chainsList.find(chain => chain.chain_name === newValue)]);
                              setStores([]);
                              setCategory([]);
                            }}
                          />
                        </FormControl>
                      )}
                    </FormControl>
                  </TableCell>}
                  {containsScope('stores') && <TableCell sx={{ width: "20vw" }}>
                    <MultiSelector
                      label={t('ciam_app.Home.stores', 'Stores')}
                      name="parsed_name"
                      value={stores}
                      setValue={setStores}
                      options={storesList.filter(store =>
                        chain?.some(chainObj => chainObj?.chain_name === store.chain_name)
                      )}
                      loading={isLoadingChains}
                      disabled={chain === null || !apps.filter(app => app.app_name === appName)[0]?.scopes?.some(
                        req => req === 'stores',
                      ) || isLoadingChains || storesList?.length === 0 || chain?.length === 0}
                      required={handleRequired('stores')}
                    />
                  </TableCell>
                  }
                  {containsScope('suppliers') && <TableCell sx={{ width: "20vw" }}>
                    <FormControl fullWidth>
                      <Autocomplete
                        filterOptions={filterOptions}
                        id="supplier"
                        disabled={!apps?.filter(app => app.app_name === appName)[0]?.scopes?.some(
                          req => req === 'suppliers',
                        ) || suppliers?.filter(supplier => chain?.some(chainObj => chainObj?.chain_name === supplier.chain_list_str))?.length === 0}
                        value={supplier}
                        options={suppliers?.filter(supplier => chain?.some(chainObj => chainObj?.chain_name === supplier.chain_list_str))?.map(option => option.id)}
                        getOptionLabel={option =>
                          suppliers?.find(supplier => supplier.id === option)
                            ?.name
                        }
                        fullWidth
                        loading={suppliers?.filter(supplier => chain?.some(chainObj => chainObj?.chain_name === supplier.chain_list_str))?.length === 0}
                        renderInput={params => (
                          <TextField
                            required={handleRequired('suppliers')}
                            {...params}
                            label={t('ciam_app.Home.supplier')}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                        onChange={(event, newValue, reason) => {
                          if (reason === 'reset') {
                            setSupplier(null);
                          }
                          setSupplier(newValue);
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  }
                  {containsScope('categories') && <TableCell sx={{ width: "20vw" }}>
                    <MultiSelector
                      label={t('ciam_app.Home.category', 'Category')}
                      name="parsed_name"
                      value={category}
                      setValue={setCategory}
                      options={categoriesList}
                      loading={isLoadingCategories}
                      disabled={stores.length === 0 || categories?.categories?.length === 0 || isLoadingCategories || !apps.filter(app => app.app_name === appName)[0]?.scopes?.some(
                        req => req === 'categories',
                      )}
                      required={handleRequired('categories')}
                    />
                  </TableCell>}
                  {apps?.filter(app => app.app_name === appName)[0]?.rol_per_scope?.length > 0 && <TableCell sx={{ width: "20vw" }}>
                    <FormControl fullWidth>
                      <Autocomplete
                        filterOptions={filterOptions}
                        id="role"
                        disabled={apps?.filter(app => app.app_name === appName)[0]?.rol_per_scope?.length === 0}
                        value={role}
                        options={apps?.filter(app => app.app_name === appName)[0]?.rol_per_scope.map(option => option)}
                        getOptionLabel={option => option}
                        fullWidth
                        renderInput={params => (
                          <TextField
                            required={handleRequired('roles')}
                            {...params}
                            label={t('ciam_app.Home.role')}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                        onChange={(event, newValue, reason) => {
                          if (reason === 'reset') {
                            setRole(null);
                          }
                          setRole(newValue);
                        }}
                      />
                    </FormControl>
                  </TableCell>}
                  {apps?.filter(app => app.app_name === appName)[0]?.features?.length > 0 && <TableCell sx={{ width: "20vw" }}>
                    <MultiSelector
                      label={t('ciam_app.Home.feature', 'Feature')}
                      name="name"
                      value={feature}
                      setValue={setFeature}
                      options={apps?.filter(app => app.app_name === appName)[0]?.features}
                      disabled={apps?.filter(app => app.app_name === appName)[0]?.features?.length === 0}
                      required={handleRequired('features')}
                    />
                  </TableCell>}
                  <TableCell>
                    <IconButton
                      sx={styles.iconButton} onClick={() => handleScopesSelection()}
                      disabled={handleDisableButton()}>
                      <IconComponent
                        iconName={'checkmark-outline'}
                        className={'icon'}
                        style={handleDisableButton() ? styles.iconComponentDisabled : styles.iconComponent}
                      />
                    </IconButton>
                  </TableCell>

                </TableRow>
              </TableBody>

            </Table>
          </TableContainer>
        </>
      )}
    </Grid >
  );
}
