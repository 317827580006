import { useEffect } from 'react';
import { Grid, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ClientApps(props) {
  const { t } = useTranslation();
  const {
    user,
    activeApps,
    setActiveApps,
    setStepList,
    steps,
    selectAll,
    setSelectAll,
    setRequireProfile,
  } = props;

  const isAdmin = user?.ciam_permissions?.scopes[0]?.role === 'admin' ? true : false;

  const handleSelectAll = event => {
    const { checked } = event.target;
    const newApps = activeApps.map(app => ({
      ...app,
      selected: checked,
      modified: checked,
    }));
    setActiveApps(newApps);
    setSelectAll(checked);
    handleAddStep(newApps);
  };

  const handleAppSelection = event => {
    const { name, checked } = event.target;
    const newApps = activeApps.map(app =>
      app.app_name === name ? { ...app, selected: checked, modified: checked } : app,
    );
    setActiveApps(newApps);
    handleAddStep(newApps);
  };

  const handleAddStep = newApps => {
    const newStepList = [];
    let decimal = 1;
    newStepList.push(steps[0]);
    newStepList.push(steps[1]);
    newApps.forEach(app => {
      if (app.selected) {
        newStepList.push({
          label: app.app_name,
          number: `2.${decimal}`,
        });
        decimal += 1;
      }
    });
    newStepList.push(steps[2]);
    setStepList(newStepList);
  };

  const handleDisableSelectAll = () => {
    if (isAdmin) {
      return false;
    }

    for (let i = 0; i < activeApps.length; i++) {
      if (!user.sites.includes(activeApps[i].app_name)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (activeApps.length > 0) {
      handleAddStep(activeApps);
      setRequireProfile(false);
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" textAlign="left">
          {t('ciam_app.Home.add_client_info')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4} display="flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  name="selectAll"
                  disabled={handleDisableSelectAll}
                />
              }
              label={t('ciam_app.Home.select_all')}
            />
          </Grid>
          {activeApps.map((app, index) => (
            <Grid item xs={4} key={index} display="flex">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={app.selected}
                    onChange={handleAppSelection}
                    name={app.app_name}
                    disabled={!user.sites.includes(app.app_name) && !isAdmin}
                  />
                }
                label={t(`ciam_app.App.${app.app_name}`)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
