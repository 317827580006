import { connect } from 'react-redux';
import Home from '../../../components/home/Home';
import { user, isSyncingUsers } from 'core/selectors';
import { syncUsers, getUsers } from 'core/slices/users';

export default connect(
  state => ({
    user: user(state),
    isSyncingUsers: isSyncingUsers(state),
  }),
  { syncUsers, getUsers },
)(Home);
