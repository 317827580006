import { connect } from 'react-redux';
import ConfirmationModal from '../../../components/clients/ConfirmationModal';
import { isLoadingUsers, client } from 'core/selectors';

export default connect(
    state => ({
        isLoadingUsers: isLoadingUsers(state),
        client: client(state)
    }),
    {},
)(ConfirmationModal);
