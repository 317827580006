export function isLoadingSession({ session: { loading } }) {
  return loading;
}

export function userInfo({ session: { userInfo } }) {
  return userInfo;
}

export function hasSession({ session: { loggedIn } }) {
  return loggedIn;
}

export function users({ users: { users } }) {
  return users;
}

export function user({ users: { user } }) {
  return user;
}

export function isLoadingUsers({ users: { loading } }) {
  return loading;
}

export function chains({ locations: { chains } }) {
  return chains;
}

export function stores({ locations: { stores } }) {
  return stores;
}

export function isLoadingChains({ locations: { loading } }) {
  return loading;
}

export function apps({ apps: { apps } }) {
  return apps;
}

export function suppliers({ suppliers: { suppliers } }) {
  return suppliers;
}

export function categories({ categories: { categories } }) {
  return categories;
}

export function isLoadingCategories({ categories: { loading } }) {
  return loading;
}

export function isSyncingUsers({ users: { syncing } }) {
  return syncing;
}

export function client({ users: { client } }) {
  return client;
}
export function clients({ clients: { clients } }) {
  return clients;
}

export function isLoadingClients({ clients: { loading } }) {
  return loading;
}
