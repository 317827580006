import { connect } from 'react-redux';
import ClientAppPermisions from '../../../../components/clients/steps/ClientAppPermissions';
import { apps, suppliers, user, isLoadingCategories, clients, isLoadingClients, isLoadingChains } from 'core/selectors';
import { getSuppliers } from 'core/slices/suppliers';
import { getClients } from 'core/slices/clients';
import { getLocations } from 'core/slices/locations';

export default connect(
  state => ({
    suppliers: suppliers(state),
    apps: apps(state),
    user: user(state),
    isLoadingCategories: isLoadingCategories(state),
    isLoadingChains: isLoadingChains(state),
    clients: clients(state),
    isLoadingClients: isLoadingClients(state),
  }),
  { getSuppliers, getClients, getLocations },
)(ClientAppPermisions);
