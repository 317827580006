import { createReducer } from './utils';

import {
    GET_CLIENTS_FAILURE,
    GET_CLIENTS_REQUEST,
    GET_CLIENTS_SUCCESS,
    SNACK_SET,
} from './constants';

const defaultState = {
    loading: false,
    errorMessage: null,
    clients: null,
};

export const reducer = createReducer(defaultState, {
    [GET_CLIENTS_REQUEST]: handleGetClientsRequest,
    [GET_CLIENTS_SUCCESS]: handleGetClientsSuccess,
    [GET_CLIENTS_FAILURE]: handleGetClientsFailure,
});

function handleGetClientsRequest(state) {
    return {
        ...state,
        loading: true,
        errorMessage: null,
    };
}

function handleGetClientsSuccess(state, { payload: { clients } }) {
    return {
        ...state,
        clients,
        loading: false,
        errorMessage: null,
    };
}

function handleGetClientsFailure(state, { payload: { error } }) {
    return {
        ...state,
        loading: false,
        errorMessage: error,
        clients: null,
    };
}

// actions

export function getClients(token) {
    return async (dispatch, getState, { services: { dataSource } }) => {
        dispatch({ type: GET_CLIENTS_REQUEST });
        try {
            const clients = await dataSource.getClients(token);
            dispatch({
                type: GET_CLIENTS_SUCCESS,
                payload: { clients },
            });
        } catch (error) {
            dispatch({
                type: GET_CLIENTS_FAILURE,
                payload: { error },
            });
            const snack = { open: true, severity: 'error', message: 'Error fetching clients: ' + error };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
