import { createReducer } from './utils';

import {
  GET_APPS_REQUEST,
  GET_APPS_SUCCESS,
  GET_APPS_FAILURE,
  SNACK_SET,
} from './constants';

const defaultState = {
  loading: true,
  errorMessage: null,
  apps: null,
};

export const reducer = createReducer(defaultState, {
  [GET_APPS_REQUEST]: handleGetAppsRequest,
  [GET_APPS_SUCCESS]: handleGetAppsSuccess,
  [GET_APPS_FAILURE]: handleGetAppsFailure,
});

function handleGetAppsRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetAppsSuccess(state, { payload: { apps } }) {
  return {
    ...state,
    apps,
    loading: false,
    errorMessage: null,
  };
}

function handleGetAppsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
    apps: null,
  };
}

// actions

export function getApps() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_APPS_REQUEST });
    try {
      const apps = await dataSource.getApps();
      dispatch({
        type: GET_APPS_SUCCESS,
        payload: { apps },
      });
    } catch (error) {
      dispatch({
        type: GET_APPS_FAILURE,
        payload: { error },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error fetching apps: ' + error,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
