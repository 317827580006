import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { reducer as sessionReducer } from './slices/session';
import { reducer as usersReducer } from './slices/users';
import { reducer as locationsReducer } from './slices/locations';
import { reducer as appsReducer } from './slices/apps';
import { reducer as suppliersReducer } from './slices/suppliers';
import { reducer as categoriesReducer } from './slices/categories';
import { reducer as clientsReducer } from './slices/clients';

// Zippedi tools reducer
import { snackAlertReducer } from '@zippeditoolsjs/blocks';

export default function _createStore(services) {
  return createStore(
    combineReducers({
      session: sessionReducer,
      users: usersReducer,
      locations: locationsReducer,
      apps: appsReducer,
      suppliers: suppliersReducer,
      categories: categoriesReducer,
      clients: clientsReducer,
      snackAlert: snackAlertReducer,
    }),
    applyMiddleware(thunk.withExtraArgument({ services })),
  );
}
