import { connect } from 'react-redux';
import ClientsList from '../../../components/clients/ClientsList';
import { getUsers, getUser, deleteUser, getClient, revokeMfa } from 'core/slices/users';
import { users, user, isLoadingUsers, chains, stores, apps, client } from 'core/selectors';

export default connect(
  state => ({
    users: users(state),
    user: user(state),
    isLoadingUsers: isLoadingUsers(state),
    chains: chains(state),
    stores: stores(state),
    apps: apps(state),
    client: client(state)
  }),
  { getUsers, getUser, deleteUser, getClient, revokeMfa },
)(ClientsList);
