import { connect } from 'react-redux';
import ClientApps from '../../../../components/clients/steps/ClientApps';
import { getUser } from 'core/slices/users';
import { user } from 'core/selectors';

export default connect(
  state => ({
    user: user(state),
  }),
  { getUser },
)(ClientApps);
