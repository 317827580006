import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, IconButton, useTheme, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import StyledModalBox from '../tools/StyledModalBox';

export default function ConfirmationModal(props) {
  const { open, handleClose, onConfirmationComplete, selectedUser, isLoadingUsers } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const styles = {
    iconComponent: {
      color:
        theme.palette.type === 'dark' ? 'white' : theme.palette.primary.main,
    },
    IconButton: {
      position: 'absolute',
      right: '0.5rem',
      top: '0.5rem',
      color: 'text.secondary',
      ':hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? `${theme.palette.primary.main}33`
            : `{theme.palette.grey[400]}33`,
      },
    },
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}>
        <StyledModalBox>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    textAlign={'left'}>
                    {`${t('ciam_app.Home.delete_client_title')} ${selectedUser?.name
                      ? selectedUser?.name
                      : selectedUser?.email
                      } ?`}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="close"
                    sx={styles.IconButton}
                    onClick={() => {
                      handleClose();
                    }}>
                    <IconComponent
                      iconName={'close-outline'}
                      className={'icon'}
                      style={styles.iconComponent}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} paddingY={2}>
              {selectedUser?.sites.length > 0 && (
                <Typography
                  id="modal-modal-description"
                  variant="subtitle1"
                  textAlign={'justify'}>
                  {t('ciam_app.Home.delete_client_sites')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={12} paddingY={2}>
              <Grid container justifyContent="space-between">
                {selectedUser?.sites.map((site, index) => (
                  <Grid container xs={4}>
                    <Grid item xs={2} key={index}>
                      <IconComponent
                        iconName={'checkmark-outline'}
                        className={'icon'}
                        style={styles.iconComponent}
                      />
                    </Grid>
                    <Grid item xs={2} key={index}>
                      <Typography
                        id="modal-modal-description"
                        variant="subtitle1"
                        textAlign={'justify'}>
                        {site}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} paddingY={2}>
              <Typography
                id="modal-modal-description"
                variant="subtitle1"
                textAlign={'justify'}>
                {t('ciam_app.Home.confirmation')}
              </Typography>
            </Grid>
            <Grid container justifyContent="space-between" display={'flex'}>
              <Grid item xs={3}>
                <Typography
                  onClick={() => {
                    handleClose();
                  }}
                  variant="subtitle2"
                  textAlign={'left'}
                  color={theme.palette.secondary.main}
                  style={{ cursor: 'pointer', userSelect: 'none' }}>
                  {t('ciam_app.Home.cancel')}
                </Typography>
              </Grid>
              <Grid container alignItems="center" justifyContent="flex-end" item xs={3}>
                {isLoadingUsers ? (
                  <CircularProgress
                    size={20}
                    style={{ color: theme.palette.primary.main }}
                  />
                ) : (
                  <Typography
                    variant="subtitle2"
                    onClick={() => {
                      onConfirmationComplete();
                    }}
                    style={{ cursor: 'pointer', userSelect: 'none', textAlign: 'right' }}
                  >
                    {t('ciam_app.Home.confirm')}
                  </Typography>
                )}
              </Grid>

            </Grid>
          </Grid>
        </StyledModalBox>
      </Modal>
    </div>
  );
}
