import { createReducer } from './utils';

import {
  GET_LOCATIONS_FAILURE,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  SNACK_SET,
} from './constants';

const defaultState = {
  loading: true,
  errorMessage: null,
  chains: null,
  stores: null,
};

export const reducer = createReducer(defaultState, {
  [GET_LOCATIONS_REQUEST]: handleGetLocationsRequest,
  [GET_LOCATIONS_SUCCESS]: handleGetLocationsSuccess,
  [GET_LOCATIONS_FAILURE]: handleGetLocationsFailure,
});

function handleGetLocationsRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetLocationsSuccess(state, { payload: { locations } }) {
  return {
    ...state,
    chains: locations.chains,
    stores: locations.stores,
    loading: false,
    errorMessage: null,
  };
}

function handleGetLocationsFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
    chains: null,
    stores: null,
  };
}

// actions

export function getLocations(token, client_id_list = [-1]) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_LOCATIONS_REQUEST });
    try {
      const locations = await dataSource.getLocations(token, client_id_list.join(','));
      dispatch({
        type: GET_LOCATIONS_SUCCESS,
        payload: { locations },
      });
    } catch (error) {
      dispatch({
        type: GET_LOCATIONS_FAILURE,
        payload: { error },
      });
      const snack = { open: true, severity: 'error', message: 'Error fetching locations: ' + error };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
