import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Grid,
  IconButton,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { generatePassword } from '@zippeditoolsjs/security';

import StyledModalBox from '../tools/StyledModalBox';
import ClientAppPermisions from '../../redux/containers/clients/steps/ClientAppPermissions';
import ClientCreation from './steps/ClientCreation';
import ClientApps from '../../redux/containers/clients/steps/ClientApps';
import ClientInfo from './steps/ClientInfo';

const steps = [
  {
    label: 'client_info',
    number: 1,
  },
  {
    label: 'app_access',
    number: 2,
  },
  {
    label: 'confirmation',
    number: 3,
  },
];

export default function ConfirmationModal(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [activeApps, setActiveApps] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [stepList, setStepList] = useState(steps);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [chainsList, setChainsList] = useState([]);
  const [storesList, setStoresList] = useState([]);
  const [selectedChains, setSelectedChains] = useState([]);
  const [originalSites, setOriginalSites] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [password, setPassword] = useState(generatePassword());
  const [requireProfile, setRequireProfile] = useState(false);
  const [error, setError] = useState(false);
  const {
    open,
    createUser,
    updateUser,
    getUsers,
    chains,
    stores,
    categories,
    user,
    getLocations,
    getCategories,
    apps,
    getApps,
    selectedUser,
    setSelectedUser,
    isLoadingUsers,
    client,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const styles = {
    iconComponent: {
      color:
        theme.palette.type === 'dark' ? 'white' : theme.palette.primary.main,
    },
    IconButton: {
      position: 'absolute',
      right: '0.5rem',
      top: '0.5rem',
      color: 'text.secondary',
      ':hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? `${theme.palette.primary.main}33`
            : `{theme.palette.grey[400]}33`,
      },
    },
  };

  const restoreDefault = () => {
    setPassword(generatePassword());
    setActiveStep(0);
    setSelectAll(false);
    setStepList(steps);
    setActiveApps(
      apps?.map(app => {
        return {
          app_name: app.app_name,
          app_tenant: app.app_tenant,
          selected: false,
          payload: [],
          permissions: [],
        };
      }),
    );
    setEmail('');
    setFirstName('');
    setLastName('');
    setRequireProfile(false);
    setActiveApps(
      apps?.map(app => {
        return {
          app_name: app.app_name,
          app_tenant: app.app_tenant,
          selected: false,
          modified: false,
          permissions: app.permissions.map(permission => {
            return {
              name: permission,
              selected: false,
            };
          }),
          payload: [],
          chains: [],
          stores: [],
          multiple_roles: app.multiple_roles,
          requirements: app.requirements,
          multiple_chains: app.multiple_chains,
          require_profile: app.require_profile,
        };
      }),
    );
    setSelectedChains([]);
    setSelectedUser(null);
  };

  const handleClose = () => {
    restoreDefault();
    setSelectedClient(null);
    props.handleClose();
  };

  const handleSubmit = () => {
    const payload = {
      email: email,
      password,
      first_name: firstName,
      last_name: lastName,
      apps: activeApps.filter(app => app.selected),
      token: user ? user.token : null,
      sites: originalSites,
      status: selectedUser ? selectedUser.status === 'Active' : true,
    };

    if (selectedUser) {
      updateUser(payload, user.token).then(() => {
        handleClose();
        getUsers();
      });
    } else {
      createUser(payload, user.token).then(() => {
        handleClose();
        getUsers();
      });
    }
  };

  useEffect(() => {
    if (!stores) {
      user && getLocations(user.token);
    } else {
      const sortedChains = chains?.sort((a, b) => {
        if (a.chain_name < b.chain_name) {
          return -1;
        }
        if (a.chain_name > b.chain_name) {
          return 1;
        }
        return 0;
      });
      const sortedStores = stores?.sort((a, b) => {
        if (a.store_name < b.store_name) {
          return -1;
        }
        if (a.store_name > b.store_name) {
          return 1;
        }
        return 0;
      });
      setChainsList(sortedChains);
      setStoresList(sortedStores);
    }
  }, [chains, user]);

  useEffect(() => {
    if (!apps) {
      getApps();
    } else {
      setActiveApps(
        apps.map(app => {
          return {
            app_name: app.app_name,
            app_tenant: app.app_tenant,
            selected: false,
            modified: false,
            permissions: app.permissions.map(permission => {
              return {
                name: permission,
                selected: false,
              };
            }),
            payload: [],
            chains: [],
            stores: [],
            multiple_roles: app.multiple_roles,
            requirements: app.requirements,
            multiple_chains: app.multiple_chains,
            require_profile: app.require_profile,
          };
        }),
      );
    }
  }, [apps]);

  useEffect(() => {
    if (client) {
      setSelectedClient(client);
    }
  }, [client]);

  useEffect(() => {
    if (!selectedUser) {
      restoreDefault();
      return;
    }

    if (!selectedClient) {
      return;
    }
    const { email, sites } = selectedUser;
    const { name, apps_permissions } = selectedClient;
    const [firstName, lastName] = name.split(/ (.+)/);
    setFirstName(firstName);
    setLastName(lastName || '');
    setEmail(email);

    const newChainsList = [];
    const updatedApps = apps.map(app => {
      const selected = sites.includes(app.app_name);
      const permissions = app.permissions.map(permission => ({
        name: permission,
        selected: apps_permissions.some(
          userApp =>
            userApp.app_name === app.app_name &&
            userApp.permissions.some(
              user_permission =>
                user_permission.name === permission && user_permission.selected,
            ),
        ),
      }));
      const payload = apps_permissions.find(
        userApp => userApp.app_name === app.app_name,
      )?.scopes;

      apps_permissions
        .filter(userApp => userApp.app_name === app.app_name)
        .forEach(userApp => {
          try {
            userApp.scopes?.forEach(scope => {
              if (
                !newChainsList.some(chain => chain.chain_name === scope.chain)
              ) {
                try {
                  const { chain_country_id, chain_id } = chainsList?.find(
                    chain => chain.chain_name === scope.chain,
                  );
                  newChainsList.push({
                    chain_country_id,
                    chain_id,
                    chain_name: scope.chain,
                  });
                } catch {
                  scope.chain.forEach(chain => {
                    if (newChainsList.some(chain => chain.chain_name === chain))
                      return;
                    newChainsList.push({
                      chain_country_id: chain.chain_country_id,
                      chain_id: chain.chain_id,
                      chain_name: chain.chain_name,
                    });
                  });
                }
              }
            });
          } catch {
            console.log(
              'Unsuported userApp format, maybe it was created with an older version of the app?',
            );
          }
        });

      return {
        app_name: app.app_name,
        app_tenant: app.app_tenant,
        selected,
        modified: false,
        permissions,
        payload,
        chains: newChainsList.filter(chain => chain.name),
        multiple_roles: app.multiple_roles,
        requirements: app.requirements,
        multiple_chains: app.multiple_chains,
        require_profile: app.require_profile,
      };
    });

    setSelectedChains(newChainsList);
    setActiveApps(updatedApps);
    setOriginalSites(sites);
  }, [selectedUser, selectedClient]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}>
        <StyledModalBox>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={11}>
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    textAlign={'left'}>
                    {selectedUser
                      ? t('ciam_app.Home.client')
                      : t('ciam_app.Home.new_client')}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="close"
                    sx={styles.IconButton}
                    onClick={() => {
                      handleClose();
                    }}>
                    <IconComponent
                      iconName={'close-outline'}
                      className={'icon'}
                      style={styles.iconComponent}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} paddingY={2}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {stepList.map(step => (
                  <Step key={step.label}>
                    <StepLabel icon={step.number}>
                      <Typography variant="body2">
                        {t(
                          `ciam_app.App.${step.label}`,
                          t(`ciam_app.Step.${step.label}`),
                        )}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} md={12} paddingY={2} paddingX={2}>
              {activeStep === 0 ? (
                <ClientInfo
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  isNew={selectedUser ? false : true}
                  setError={setError}
                />
              ) : activeStep === 1 ? (
                <ClientApps
                  activeApps={activeApps}
                  setActiveApps={setActiveApps}
                  setStepList={setStepList}
                  steps={steps}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  setRequireProfile={setRequireProfile}
                />
              ) : activeStep === stepList.length - 1 ? (
                <ClientCreation
                  activeApps={activeApps}
                  username={`${firstName} ${lastName}`}
                  email={email}
                  selectedChains={selectedChains}
                  password={password}
                  showPassword={selectedUser ? false : true}
                />
              ) : (
                <ClientAppPermisions
                  currentStep={stepList[activeStep].label}
                  email={email}
                  categories={categories}
                  getCategories={getCategories}
                  chainsList={chainsList}
                  storesList={storesList}
                  setSelectedChains={setSelectedChains}
                  selectedChains={selectedChains}
                  activeApps={activeApps}
                  setActiveApps={setActiveApps}
                  setRequireProfile={setRequireProfile}
                />
              )}
            </Grid>
            <Grid container justifyContent="space-between" display={'flex'}>
              <Grid item xs={3}>
                <Typography
                  onClick={() => {
                    if (activeStep !== 0) {
                      setActiveStep(prev => prev - 1);
                    } else {
                      handleClose();
                    }
                  }}
                  variant="subtitle2"
                  textAlign={'left'}
                  color={theme.palette.secondary.main}
                  style={{ cursor: 'pointer', userSelect: 'none' }}>
                  {activeStep !== 0
                    ? t('ciam_app.Home.back')
                    : t('ciam_app.Home.cancel')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={2}>
                    {isLoadingUsers ? (
                      <CircularProgress
                        size={20}
                        style={{ color: theme.palette.primary.main }}
                      />
                    ) : (
                      <Typography
                        variant="subtitle2"
                        textAlign={'right'}
                        onClick={() => {
                          if (!requireProfile && !error) {
                            if (activeStep !== stepList.length - 1) {
                              setActiveStep(prev => prev + 1);
                            } else {
                              handleSubmit();
                            }
                          }
                        }}
                        style={{
                          cursor:
                            !requireProfile && !error ? 'pointer' : 'no-drop',
                          userSelect: 'none',
                        }}>
                        {activeStep !== stepList.length - 1
                          ? t('ciam_app.Home.next')
                          : t('ciam_app.Home.confirm')}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledModalBox>
      </Modal>
    </div>
  );
}
