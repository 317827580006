import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  IconButton,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  Chip,
  FormControl,
  InputLabel,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { MultiSelector } from '@zippeditoolsjs/blocks';

import ConfirmationModal from '../../redux/containers/clients/ConfirmationModal';
import MFARevokeConfirmationModal from './MFARevokeConfirmationModal';
import StyledOutlinedInput from '../tools/StyledOutlinedInput';

export default function ProfileForm(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(0);
  const [userList, setUserList] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openMFARevokeConfirmationModal, setOpenMFARevokeConfirmationModal] = useState(false);
  const [translatedApps, setTranslatedApps] = useState([]);
  const [email, setEmail] = useState('');
  const [selectedApps, setSelectedApps] = useState([]);

  const { t } = useTranslation();
  const {
    users,
    getUsers,
    deleteUser,
    setOpenUserCreationModal,
    setSelectedUser,
    selectedUser,
    getUser,
    user,
    chains,
    stores,
    apps,
    getClient,
    client,
    revokeMfa,
    isLoadingUsers,
  } = props;
  const theme = useTheme();
  const styles = {
    iconComponent: {
      color:
        theme.palette.type === 'dark' ? 'white' : theme.palette.primary.main,
    },
    iconComponentDisabled: {
      color: theme.palette.type === 'dark' ? 'white' : theme.palette.grey[400],
    },
    iconButton: {
      ':hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? `${theme.palette.primary.main}33`
            : `{theme.palette.grey[400]}33`,
      },
    },
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenUserCreationModal = selectedUser => {
    setOpenUserCreationModal(true);
    setSelectedUser(selectedUser);
    getClient(selectedUser.tenant, selectedUser.email)
  };

  const handleOpenConfirmationModal = selectedUser => {
    setSelectedUser(selectedUser);
    setOpenConfirmationModal(true);
    getClient(selectedUser.tenant, selectedUser.email);
  };

  const handleOpenMFARevokeConfirmationModal = selectedUser => {
    setSelectedUser(selectedUser);
    setOpenMFARevokeConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setSelectedUser(null);
  };

  const handleCloseMFARevokeConfirmationModal = () => {
    setOpenMFARevokeConfirmationModal(false);
    setSelectedUser(null);
  };

  const handleDisableButton = sites => {
    if (!chains || !stores) {
      return true;
    }
    if (user?.ciam_permissions?.scopes[0]?.role === 'admin') {
      return false;
    }

    let user_sites = user?.sites ? user.sites : [];
    let disable = false;
    for (let i = 0; i < sites.length; i++) {
      if (!user_sites.includes(sites[i])) {
        disable = true;
        break;
      }
    }
    return disable;
  };

  const handleDeleteUser = () => {
    const payload = {
      uid: selectedUser.uid,
      tenant: selectedUser.tenant,
        apps_permissions: client.apps_permissions,
      email: selectedUser.email,
    };
    deleteUser(user.token, payload).then(() => {
      getUsers();
    });
    handleCloseConfirmationModal();
  };

  useEffect(() => {
    if (!users) {
      getUsers();
    } else {
      const sortedUsers = users.sort((a, b) => {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      });
      if (email !== '') {
        setUserList(sortedUsers.filter(user => user.email.includes(email)));
      } else {
        setUserList(sortedUsers);
      }
    }
  }, [users]);

  useEffect(() => {
    if (!user) {
      getUser();
    }
  }, [user]);

  useEffect(() => {
    const sortedUsers = users ? users.sort((a, b) => {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
      return 0;
    }) : [];

    if (selectedApps.length > 0) {
      let filteredUsers = [];
      selectedApps.forEach(app => {
        if (email !== '') {
          filteredUsers = filteredUsers.concat(
            sortedUsers.filter(
              user =>
                user.sites.includes(app.app_name) &&
                user.email.includes(email),
            ),
          );
        } else {
          filteredUsers = filteredUsers.concat(
            sortedUsers.filter(user => user.sites.includes(app.app_name)),
          );
        }
      });
      setUserList(filteredUsers);
    } else {
      if (email !== '') {
        setUserList(sortedUsers.filter(user => user.email.includes(email)));
      } else {
        setUserList(sortedUsers);
      }
    }
  }, [selectedApps, email]);

  useEffect(() => {
    if (apps) {
      let translatedApps = [];
      apps.forEach(app => {
        translatedApps.push({
          app_name: app.app_name,
          app_name_translated: t(`ciam_app.App.${app.app_name}`),
        });
      });
      setTranslatedApps(translatedApps);
    }
  }, [apps]);


  useEffect(() => {
    if (userList) {
      setEmptyRows(
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0,
      );
    }
  }, [page, rowsPerPage, userList]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3} sx={{ marginTop: '1rem', marginLeft: '1rem' }}>
          <FormControl fullWidth size="small">
            <InputLabel>{t('ciam_app.Home.search')}</InputLabel>
            <StyledOutlinedInput
              id="outlined-basic"
              label={t('ciam_app.Home.search')}
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                setPage(0);
              }}
              fullWidth
              endAdornment={
                <IconButton
                  aria-label="clear search"
                  edge="end"
                  onClick={() => {
                    setEmail('');
                    setUserList(users);
                  }}>
                  <IconComponent
                    iconName={'close-outline'}
                    className={'icon'}
                    style={styles.iconComponent}
                  />
                </IconButton>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} sx={{ marginTop: '1rem', marginLeft: '1rem' }}>
          <MultiSelector
            label={t('ciam_app.Home.sites')}
            options={translatedApps}
            value={selectedApps}
            setValue={setSelectedApps}
            name="app_name_translated"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper}>
            <Table aria-label="clients table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ paddingLeft: '2%' }}>
                    <Typography variant="body2" color={'text.secondary'}>
                      {t('ciam_app.Home.client_name')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color={'text.secondary'}>
                      {t('ciam_app.Home.email')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color={'text.secondary'}>
                      {t('ciam_app.Home.sites')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color={'text.secondary'}>
                      {t('ciam_app.Home.type')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color={'text.secondary'}>
                      {t('ciam_app.Home.status')}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography variant="body2" color={'text.secondary'}>
                      {t('ciam_app.Home.edit')}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography variant="body2" color={'text.secondary'}>
                      {t('ciam_app.Home.revoke_mfa')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color={'text.secondary'}>
                      {t('ciam_app.Home.delete')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? userList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                  : userList
                ).map(row => (
                  <TableRow key={row.uid}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ paddingLeft: '2%' }}>
                      <Typography variant="subtitle2">{row.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{row.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {row.sites
                          .map((site, index) => {
                            return t(`ciam_app.App.${site}`);
                          })
                          .join(', ')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {t(`ciam_app.Home.${row.type}`)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {row.status === 'Active' ? (
                        <Chip
                          label={t('ciam_app.Home.active')}
                          sx={{
                            backgroundColor: `${theme.palette.success.main}66`,
                          }}
                        />
                      ) : (
                        <Chip
                          label={t('ciam_app.Home.inactive')}
                          sx={{
                            backgroundColor: `${theme.palette.warning.main}66`,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        sx={styles.iconButton}
                        disabled={handleDisableButton(row.sites)}
                        onClick={() => {
                          handleOpenUserCreationModal(row);
                        }}>
                        <IconComponent
                          iconName={'pencil-outline'}
                          className={'icon'}
                          style={
                            handleDisableButton(row.sites)
                              ? styles.iconComponentDisabled
                              : styles.iconComponent
                          }
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        sx={styles.iconButton}
                        disabled={handleDisableButton(row.sites)}
                        onClick={() => {
                          handleOpenMFARevokeConfirmationModal(row);
                        }}>
                        <IconComponent
                          iconName={'lock-closed-outline'}
                          className={'icon'}
                          style={
                            handleDisableButton(row.sites)
                              ? styles.iconComponentDisabled
                              : styles.iconComponent
                          }
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        sx={styles.iconButton}
                        onClick={() => handleOpenConfirmationModal(row)}
                        disabled={handleDisableButton(row.sites)}>
                        <IconComponent
                          iconName={'trash-outline'}
                          className={'icon'}
                          style={
                            handleDisableButton(row.sites)
                              ? styles.iconComponentDisabled
                              : styles.iconComponent
                          }
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      25,
                      50,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={8}
                    count={userList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={t('ciam_app.Home.clients_per_page')}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid >
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        selectedUser={selectedUser}
        onConfirmationComplete={handleDeleteUser}
        isLoadingUsers={isLoadingUsers}
      />
      <MFARevokeConfirmationModal
        open={openMFARevokeConfirmationModal}
        handleClose={handleCloseMFARevokeConfirmationModal}
        selectedUser={selectedUser}
        onConfirmationComplete={revokeMfa}
        isLoadingUsers={isLoadingUsers}
      />
    </>
  );
}
