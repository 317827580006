export default function DataSourceService({ baseUrl, http }) {
  const PATH_USERS = '/users';
  const PATH_LOCATIONS = '/chains';
  const PATH_APPS = '/apps';
  const PATH_USER = '/user';
  const PATH_SUPPLIERS = '/suppliers';
  const PATH_CATEGORIES = '/categories'
  const PATH_SYNC = '/sync'
  const PATH_CLIENTS = '/clients';
  const PATH_MFA = '/mfa';

  return {
    getUsers,
    createUser,
    updateUser,
    deleteUser,
    getLocations,
    getApps,
    getUser,
    getSuppliers,
    getCategories,
    syncUsers,
    getClient,
    getClients,
    revokeMfa,
  };

  function getUsers() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USERS}`,
    });
  }

  function createUser(body, token) {
    return http.postAuthenticatedWithHeaders({
      url: `${baseUrl}${PATH_USERS}`,
      body: body,
      headers: { token: token },
    });
  }

  function updateUser(body, token) {
    return http.putAuthenticatedWithHeaders({
      url: `${baseUrl}${PATH_USERS}`,
      body: body,
      headers: { token: token },
    });
  }

  function deleteUser(token, body) {
    return http.deleteAuthenticatedWithHeaders({
      url: `${baseUrl}${PATH_USERS}`,
      body: body,
      headers: { token: token },
    });
  }

  function getLocations(token, client_id_list) {
    return http.getAuthenticatedWithHeaders({
      url: `${baseUrl}${PATH_LOCATIONS}`,
      headers: { token },
      query: { client_id_list },
    });
  }

  function getApps() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_APPS}`,
    });
  }

  function getUser() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USER}`,
    });
  }

  function getSuppliers(token) {
    return http.getAuthenticatedWithHeaders({
      url: `${baseUrl}${PATH_SUPPLIERS}`,
      headers: { token: token },
    });
  }

  function getCategories(token, store_id) {
    return http.getAuthenticatedWithHeaders({
      url: `${baseUrl}${PATH_CATEGORIES}`,
      headers: { token: token },
      query: { store_id },
    });
  }

  function syncUsers(token) {
    return http.getAuthenticatedWithHeaders({
      url: `${baseUrl}${PATH_SYNC}`,
      headers: { token: token },
    });
  }

  function getClient(tenant, email) {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_USER}`,
      query: { tenant, email },
    });
  }

  function getClients(token) {
    return http.getAuthenticatedWithHeaders({
      url: `${baseUrl}${PATH_CLIENTS}`,
      headers: { token: token },
    });
  }

  function revokeMfa(body) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_MFA}`,
      body: body,
    });
  }
}
