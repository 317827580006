import { createReducer } from './utils';

import {
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  SYNC_USERS_FAILURE,
  SYNC_USERS_REQUEST,
  SYNC_USERS_SUCCESS,
  GET_CLIENT_FAILURE,
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  REVOKE_MFA_FAILURE,
  REVOKE_MFA_REQUEST,
  REVOKE_MFA_SUCCESS,
  SNACK_SET,
} from './constants';

const defaultState = {
  loading: true,
  syncing: false,
  errorMessage: null,
  users: null,
  user: null,
  client: null,
};

export const reducer = createReducer(defaultState, {
  [GET_USERS_REQUEST]: handleGetUsersRequest,
  [GET_USERS_SUCCESS]: handleGetUsersSuccess,
  [GET_USERS_FAILURE]: handleGetUsersFailure,
  [CREATE_USER_REQUEST]: handleCreateUserRequest,
  [CREATE_USER_SUCCESS]: handleCreateUserSuccess,
  [CREATE_USER_FAILURE]: handleCreateUserFailure,
  [UPDATE_USER_REQUEST]: handleUpdateUserRequest,
  [UPDATE_USER_SUCCESS]: handleUpdateUserSuccess,
  [UPDATE_USER_FAILURE]: handleUpdateUserFailure,
  [DELETE_USER_REQUEST]: handleDeleteUserRequest,
  [DELETE_USER_SUCCESS]: handleDeleteUserSuccess,
  [DELETE_USER_FAILURE]: handleDeleteUserFailure,
  [GET_USER_REQUEST]: handleGetUserRequest,
  [GET_USER_SUCCESS]: handleGetUserSuccess,
  [GET_USER_FAILURE]: handleGetUserFailure,
  [SYNC_USERS_REQUEST]: handleSyncUsersRequest,
  [SYNC_USERS_SUCCESS]: handleSyncUsersSuccess,
  [SYNC_USERS_FAILURE]: handleSyncUsersFailure,
  [GET_CLIENT_REQUEST]: handleGetClientRequest,
  [GET_CLIENT_SUCCESS]: handleGetClientSuccess,
  [GET_CLIENT_FAILURE]: handleGetClientFailure,
  [REVOKE_MFA_REQUEST]: handleRevokeMfaRequest,
  [REVOKE_MFA_SUCCESS]: handleRevokeMfaSuccess,
  [REVOKE_MFA_FAILURE]: handleRevokeMfaFailure,
});

function handleGetUsersRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetUsersSuccess(state, { payload: { users } }) {
  return {
    ...state,
    users,
    loading: false,
    errorMessage: null,
  };
}

function handleGetUsersFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
    users: null,
  };
}

function handleCreateUserRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleCreateUserSuccess(state) {
  return {
    ...state,
    loading: false,
    errorMessage: null,
  };
}

function handleCreateUserFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
  };
}

function handleUpdateUserRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleUpdateUserSuccess(state) {
  return {
    ...state,
    loading: false,
    errorMessage: null,
  };
}

function handleUpdateUserFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
  };
}

function handleDeleteUserRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleDeleteUserSuccess(state) {
  return {
    ...state,
    loading: false,
    errorMessage: null,
  };
}

function handleDeleteUserFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
  };
}

function handleGetUserRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetUserSuccess(state, { payload: { user } }) {
  return {
    ...state,
    user,
    loading: false,
    errorMessage: null,
  };
}

function handleGetUserFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
    user: null,
  };
}

function handleSyncUsersRequest(state) {
  return {
    ...state,
    syncing: true,
    errorMessage: null,
  };
}

function handleSyncUsersSuccess(state) {
  return {
    ...state,
    syncing: false,
    errorMessage: null,
  };
}

function handleSyncUsersFailure(state, { payload: { error } }) {
  return {
    ...state,
    syncing: false,
    errorMessage: error,
  };
}

function handleGetClientRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetClientSuccess(state, { payload: { client } }) {
  return {
    ...state,
    client,
    loading: false,
    errorMessage: null,
  };
}

function handleGetClientFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
    client: null,
  };
}

function handleRevokeMfaRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleRevokeMfaSuccess(state) {
  return {
    ...state,
    loading: false,
    errorMessage: null,
  };
}

function handleRevokeMfaFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
  };
}

// actions

export function getUsers() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_USERS_REQUEST });
    try {
      const users = await dataSource.getUsers();
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: { users },
      });
    } catch (error) {
      dispatch({
        type: GET_USERS_FAILURE,
        payload: { error },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error fetching users: ' + error,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function createUser(body, token) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: CREATE_USER_REQUEST });
    try {
      await dataSource.createUser(body, token);
      dispatch({
        type: CREATE_USER_SUCCESS,
      });
      const snack = {
        open: true,
        severity: 'success',
        message: 'User created',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: CREATE_USER_FAILURE,
        payload: { error },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error creating user: ' + error,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function updateUser(body, token) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: UPDATE_USER_REQUEST });
    try {
      await dataSource.updateUser(body, token);
      dispatch({
        type: UPDATE_USER_SUCCESS,
      });
      const snack = {
        open: true,
        severity: 'success',
        message: 'User updated',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: { error },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error updating user: ' + error,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function deleteUser(token, body) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: DELETE_USER_REQUEST });
    try {
      await dataSource.deleteUser(token, body);
      dispatch({
        type: DELETE_USER_SUCCESS,
      });
      const snack = {
        open: true,
        severity: 'success',
        message: 'User deleted',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: DELETE_USER_FAILURE,
        payload: { error },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error deleting user: ' + error,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getUser() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_USER_REQUEST });
    try {
      const user = await dataSource.getUser();
      dispatch({
        type: GET_USER_SUCCESS,
        payload: { user },
      });
    } catch (error) {
      dispatch({
        type: GET_USER_FAILURE,
        payload: { error },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error fetching user: ' + error,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function syncUsers(token) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: SYNC_USERS_REQUEST });
    try {
      await dataSource.syncUsers(token);
      dispatch({
        type: SYNC_USERS_SUCCESS,
      });
      const snack = {
        open: true,
        severity: 'success',
        message: 'Users synced',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: SYNC_USERS_FAILURE,
        payload: { error },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error syncing users: ' + error,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getClient(tenant, email) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_CLIENT_REQUEST });
    try {
      const client = await dataSource.getClient(tenant, email);
      dispatch({
        type: GET_CLIENT_SUCCESS,
        payload: { client },
      });
    } catch (error) {
      dispatch({
        type: GET_CLIENT_FAILURE,
        payload: { error },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error fetching client: ' + error,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function revokeMfa(body) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: REVOKE_MFA_REQUEST });
    try {
      await dataSource.revokeMfa(body);
      dispatch({
        type: REVOKE_MFA_SUCCESS,
      });
      const snack = { open: true, severity: 'success', message: 'MFA revoked' };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: REVOKE_MFA_FAILURE,
        payload: { error },
      });
      const snack = {
        open: true,
        severity: 'error',
        message: 'Error revoking MFA: ' + error,
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
