import { createReducer } from './utils';

import {
  GET_SUPPLIERS_FAILURE,
  GET_SUPPLIERS_REQUEST,
  GET_SUPPLIERS_SUCCESS,
  SNACK_SET,
} from './constants';

const defaultState = {
  loading: true,
  errorMessage: null,
  suppliers: null,
};

export const reducer = createReducer(defaultState, {
  [GET_SUPPLIERS_REQUEST]: handleGetSuppliersRequest,
  [GET_SUPPLIERS_SUCCESS]: handleGetSuppliersSuccess,
  [GET_SUPPLIERS_FAILURE]: handleGetSuppliersFailure,
});

function handleGetSuppliersRequest(state) {
  return {
    ...state,
    loading: true,
    errorMessage: null,
  };
}

function handleGetSuppliersSuccess(state, { payload: { suppliers } }) {
  return {
    ...state,
    suppliers,
    loading: false,
    errorMessage: null,
  };
}

function handleGetSuppliersFailure(state, { payload: { error } }) {
  return {
    ...state,
    loading: false,
    errorMessage: error,
    suppliers: null,
  };
}

// actions

export function getSuppliers(token) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SUPPLIERS_REQUEST });
    try {
      const suppliers = await dataSource.getSuppliers(token);
      dispatch({
        type: GET_SUPPLIERS_SUCCESS,
        payload: { suppliers },
      });
    } catch (error) {
      dispatch({
        type: GET_SUPPLIERS_FAILURE,
        payload: { error },
      });
      const snack = { open: true, severity: 'error', message: 'Error fetching suppliers: ' + error };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
