import qs from 'querystring';

export default function HTTPService() {
  const HTTP_METHOD_GET = 'GET';
  const HTTP_METHOD_POST = 'POST';
  const HTTP_METHOD_PUT = 'PUT';
  const HTTP_METHOD_PATCH = 'PATCH';
  const HTTP_METHOD_DELETE = 'DELETE';
  const HTTP_HEADER_ACCEPT = 'Accept';
  const HTTP_HEADER_CONTENT_TYPE = 'Content-Type';
  const HTTP_CREDENTIALS = 'credentials';
  const HTTP_CREDENTIALS_TYPE = 'include';

  const MIME_TYPE_JSON = 'application/json;charset=utf-8';
  const { REACT_APP_LOGING_MODE } = process.env;

  return {
    getAuthenticated,
    postAuthenticated,
    putAuthenticated,
    deleteAuthenticated,
    patchAuthenticated,
    getAuthenticatedWithHeaders,
    postAuthenticatedWithHeaders,
    putAuthenticatedWithHeaders,
    deleteAuthenticatedWithHeaders,
  };

  function getAuthenticated({ url, query }) {
    return makeRequest({
      method: HTTP_METHOD_GET,
      url,
      query,
    });
  }

  function getAuthenticatedWithHeaders({ url, query, headers }) {
    return makeRequest({
      method: HTTP_METHOD_GET,
      url,
      query,
      headers,
    });
  }

  function postAuthenticated({ url, body }) {
    return makeRequest({
      method: HTTP_METHOD_POST,
      url,
      body,
    });
  }

  function postAuthenticatedWithHeaders({ url, body, headers }) {
    return makeRequest({
      method: HTTP_METHOD_POST,
      url,
      body,
      headers,
    });
  }

  function putAuthenticated({ url, body }) {
    return makeRequest({
      method: HTTP_METHOD_PUT,
      url,
      body,
    });
  }
  function putAuthenticatedWithHeaders({ url, body, headers }) {
    return makeRequest({
      method: HTTP_METHOD_PUT,
      url,
      body,
      headers,
    });
  }

  function patchAuthenticated({ url, body }) {
    return makeRequest({
      method: HTTP_METHOD_PATCH,
      url,
      body,
    });
  }

  function deleteAuthenticated({ url, body }) {
    return makeRequest({
      method: HTTP_METHOD_DELETE,
      url,
      body,
    });
  }

  function deleteAuthenticatedWithHeaders({ url, body, headers }) {
    return makeRequest({
      method: HTTP_METHOD_DELETE,
      url,
      body,
      headers,
    });
  }

  async function makeRequest({
    method,
    url,
    query = {},
    body = {},
    headers = {},
  }) {
    const finalUrl =
      query && Object.keys(query).length
        ? `${url}?${qs.stringify(query)}`
        : url;

    const finalHeaders = Object.keys(headers).reduce((acc, headerKey) => {
      acc.append(headerKey, headers[headerKey]);
      return acc;
    }, new Headers());

    finalHeaders.set(HTTP_HEADER_ACCEPT, MIME_TYPE_JSON);

    if (
      method === HTTP_METHOD_POST ||
      method === HTTP_METHOD_PUT ||
      method === HTTP_METHOD_PATCH ||
      method === HTTP_METHOD_DELETE
    ) {
      finalHeaders.set(HTTP_HEADER_CONTENT_TYPE, MIME_TYPE_JSON);
    }

    const requestDescription =
      method === HTTP_METHOD_POST ||
      method === HTTP_METHOD_PUT ||
      method === HTTP_METHOD_PATCH ||
      method === HTTP_METHOD_DELETE
        ? {
            method,
            headers: finalHeaders,
            body: JSON.stringify(body),
            [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
          }
        : {
            method,
            headers: finalHeaders,
            [HTTP_CREDENTIALS]: HTTP_CREDENTIALS_TYPE,
          };

    let response;
    try {
      response = await fetch(finalUrl, requestDescription);
    } catch (error) {
      console.log(
        `%c error fetching with error: ${error} in ${finalUrl}`,
        'color: #BB0042',
      );
      response = error;
    }

    let responseBody = {};
    try {
      responseBody = await response.json();
    } catch (error) {
      if (REACT_APP_LOGING_MODE == 'DEV') {
        console.log(
          `%c error parsing response body ${error} in ${finalUrl}`,
          'color: #FF0042',
        );
      }
    }

    if (response.ok) {
      return responseBody;
    } else if (responseBody && Object.keys(responseBody).length > 0) {
      // error with message to display in snack bar
      throw new Error(responseBody.message);
    } else {
      try {
        // handle everything else
        const responseText = await response.text();
        throw new Error(responseText);
      } catch (error) {
        // connection errors
        throw new Error('Internal Server Error');
      }
    }
  }
}
