import { connect } from 'react-redux';
import UserCreationModal from '../../../components/clients/UserCreationModal';
import { createUser, updateUser, getUsers } from 'core/slices/users';
import { getLocations } from 'core/slices/locations';
import { getCategories } from 'core/slices/categories';
import { getApps } from 'core/slices/apps';
import { chains, stores, apps, user, categories, isLoadingUsers, client } from 'core/selectors';

export default connect(
  state => ({
    chains: chains(state),
    stores: stores(state),
    apps: apps(state),
    user: user(state),
    categories: categories(state),
    isLoadingUsers: isLoadingUsers(state),
    client: client(state)
  }),
  { createUser, updateUser, getUsers, getLocations, getApps, getCategories },
)(UserCreationModal);
