import { Typography, Grid, TextField, Tooltip, FormControl, Input, useTheme, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

export default function ClientAppPermisions(props) {
  const { t } = useTranslation();
  const { email, username, activeApps, selectedChains, password, showPassword } = props;

  const selectedApps = activeApps.filter(app => app.selected);
  const selectedAppsNames = selectedApps.map(app => app.app_name).join(', ');

  const theme = useTheme();
  const styles = {
    iconComponent: {
      color:
        theme.palette.type === 'dark' ? 'white' : theme.palette.primary.main,
    },
    iconButton: {
      ':hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? `${theme.palette.primary.main}33`
            : `{theme.palette.grey[400]}33`,
      },
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography textAlign={'left'} variant="subtitle1">
            {t('ciam_app.Home.user_name')}
          </Typography>
          <TextField value={username} variant="standard" fullWidth disabled />
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign={'left'} variant="subtitle1">
            {t('ciam_app.Home.user_apps')}
          </Typography>
          <Tooltip title={selectedAppsNames}>
            <TextField
              value={selectedAppsNames}
              variant="standard"
              fullWidth
              disabled
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign={'left'} variant="subtitle1">
            {t('ciam_app.Home.user_email')}
          </Typography>
          <TextField value={email} variant="standard" fullWidth disabled />
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign={'left'} variant="subtitle1">
            {t('ciam_app.Home.user_chains')}
          </Typography>
          <Tooltip
            title={selectedChains?.map(chain => `${chain?.chain_name}`)
              .join(', ')}>
            <TextField
              variant="standard"
              fullWidth
              disabled
              value={selectedChains?.map(chain => chain?.chain_name).join(', ')}
            />
          </Tooltip>
        </Grid>
        {showPassword && (<Grid item xs={6}>
          <Typography textAlign={'left'} variant="subtitle1">
            {t('ciam_app.Home.password')}
          </Typography>
          <Tooltip
            title={selectedChains?.map(chain => `${chain?.chain_name}`)
              .join(', ')}>
            <FormControl fullWidth>
              <Input
                variant="standard"
                fullWidth
                disabled
                value={password}
                endAdornment={
                  <IconButton
                    style={styles.iconButton}
                    onClick={() => {
                      navigator.clipboard.writeText(password);
                    }}>
                    <IconComponent
                      iconName={'clipboard-outline'}
                      className={'icon'}
                      style={styles.iconComponent}
                    />
                  </IconButton>
                }
              />
            </FormControl>
          </Tooltip>
        </Grid>)}
      </Grid>
    </Grid>
  );
}
