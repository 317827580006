import React, { Component } from 'react';
import { Grid, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

// My components
import MyMenu from '../../redux/containers/navbar/MyMenu';

//Icons
import logo_zippedi from '../../../images/zippedi_only_letters_white.png';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

// Styled components
const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const drawerWidth = 240;

const styles = {
  logo: {
    height: 50,
    width: 120,
  },
};

class Navbar extends Component {
  render() {
    const { open, handleDrawer, toggleTheme, isDarkTheme, toogleTranslation } = this.props;

    return (
      <AppBar position="fixed" open={open} sx={{ bgcolor: isDarkTheme ? 'paper.main' : 'primary.main' }}>
        <Toolbar>
          {/* Menu open button */}
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{
              marginRight: 4,
              ...(open && { display: 'none' }),
              color: 'white',
            }}>
            <IconComponent iconName={'menu'} className={'icon'} />
          </IconButton>

          <img style={styles.logo} src={logo_zippedi} alt="logo_zippedi" />

          {/* spacing */}
          <Grid sx={{ flexGrow: 1 }} />

          <MyMenu
            toggleTheme={toggleTheme}
            toogleTranslation={toogleTranslation}
          />
        </Toolbar>
      </AppBar>
    );
  }
}

export default Navbar;
