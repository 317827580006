import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Paper, useTheme, IconButton, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

import ClientsList from '../../redux/containers/clients/ClientsList';
import UserCreationModal from '../../redux/containers/clients/UserCreationModal';

export default function Home(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { inputBackground = 'white', Inputcolor, user, isSyncingUsers, syncUsers, getUsers } = props;
  const lightBackground = inputBackground
    ? inputBackground
    : Object.values(theme.palette[inputBackground])[0];
  const styles = {
    paper: {
      width: '100%',
      height: '100%',
      background:
        theme.palette.type === 'light'
          ? lightBackground
          : theme.palette.paper.main,
      color: Inputcolor
        ? Inputcolor
        : theme.palette.type === 'light'
          ? theme.palette.primary.main + 'D9'
          : 'rgba(255, 255, 255, 0.87)',
    },
    typography: {
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
    iconComponent: {
      color:
        theme.palette.type === 'dark' ? theme.palette.primary.main : 'white'
    },
    iconButton: {
      ':hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? `${theme.palette.primary.main}33`
            : `{theme.palette.grey[400]}33`,
      },
    },
  };
  const [openUserCreationModal, setOpenUserCreationModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <>
      <Grid container paddingBottom={2}>
        <Grid container xs={12} alignItems="center">
          <Grid item xs={5} md={7}>
            <Typography variant="h6" sx={styles.typography}>
              {t('ciam_app.Home.clients')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={7}
            md={5}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              onClick={() => setOpenUserCreationModal(true)}
              variant="contained"
              color="primary"
              size="medium"
              loading={!user?.token}
              disabled={!user?.token}
              style={{ textTransform: 'capitalize' }}>
              <Typography variant={'inherit'}>
                {t('ciam_app.Home.new_client')}
              </Typography>
            </LoadingButton>
            <Tooltip title={t('ciam_app.Home.sync')}>
              <LoadingButton
                onClick={() => syncUsers(user.token).then(() => getUsers())}
                variant="contained"
                color="primary"
                size="medium"
                loading={!user?.token || isSyncingUsers}
                disabled={!user?.token || isSyncingUsers || !user?.ciam_permissions?.permissions?.includes('canSync')}
                style={{ textTransform: 'capitalize', marginLeft: "1em" }}>
                {(user?.token && !isSyncingUsers) && (<IconButton
                  style={styles.iconButton}
                  onClick={() => { }}>
                  <IconComponent
                    iconName={'sync-outline'}
                    className={'icon'}
                    style={styles.iconComponent}
                  />
                </IconButton>)}

              </LoadingButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper sx={styles.paper}>
            <Grid container justifyContent="space-between">
              {
                <ClientsList
                  setOpenUserCreationModal={setOpenUserCreationModal}
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                />
              }
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <UserCreationModal
        open={openUserCreationModal}
        handleClose={() => setOpenUserCreationModal(false)}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
}
