import { useState } from 'react';
import { Grid, Select, Typography } from '@mui/material';
import StyledOutlinedInput from '../../tools/StyledOutlinedInput';
import { useTranslation } from 'react-i18next';

export default function ClientInfo(props) {
  const [emailError, setEmailError] = useState(false);
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    isNew,
    setError,
  } = props;
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography textAlign={'left'} variant="subtitle1">
          {isNew
            ? t('ciam_app.Home.new_first_name')
            : t('ciam_app.Home.user_name')}
        </Typography>
        <StyledOutlinedInput
          id="outlined-basic"
          variant="outlined"
          name="first_name"
          value={firstName}
          onChange={event => setFirstName(event.target.value)}
          fullWidth
          placeholder={t('John')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography textAlign={'left'} variant="subtitle1">
          {isNew
            ? t('ciam_app.Home.new_last_name')
            : t('ciam_app.Home.user_last_name')}
        </Typography>
        <StyledOutlinedInput
          id="outlined-basic"
          variant="outlined"
          name="last_name"
          value={lastName}
          onChange={event => setLastName(event.target.value)}
          fullWidth
          placeholder={t('Doe')}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography textAlign={'left'} variant="subtitle1">
          {isNew ? t('ciam_app.Home.new_email') : t('ciam_app.Home.user_email')}
        </Typography>
        <StyledOutlinedInput
          id="outlined-basic"
          variant="outlined"
          name="email"
          value={email}
          error={emailError}
          onChange={e => {
            if (e.target.value === '') {
              setEmailError(false);
              setError(false);
              setEmail(e.target.value);
            } else {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(e.target.value)) {
                setEmailError(true);
                setError(true);
              } else {
                setEmailError(false);
                setError(false);
              }
              setEmail(e.target.value);
            }
          }}
          fullWidth
          placeholder={'john.doe@example.com'}
        />
      </Grid>
    </Grid>
  );
}
