import { createReducer } from './utils';

import {
    GET_CATEGORIES_FAILURE,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    SNACK_SET,
} from './constants';

const defaultState = {
    loading: false,
    errorMessage: null,
    categories: null,
};

export const reducer = createReducer(defaultState, {
    [GET_CATEGORIES_REQUEST]: handleGetCategoriesRequest,
    [GET_CATEGORIES_SUCCESS]: handleGetCategoriesSuccess,
    [GET_CATEGORIES_FAILURE]: handleGetCategoriesFailure,
});

function handleGetCategoriesRequest(state) {
    return {
        ...state,
        loading: true,
        errorMessage: null,
        categories: null,
    };
}

function handleGetCategoriesSuccess(state, { payload: { categories } }) {
    return {
        ...state,
        categories,
        loading: false,
        errorMessage: null,
    };
}

function handleGetCategoriesFailure(state, { payload: { error } }) {
    return {
        ...state,
        loading: false,
        errorMessage: error,
        categories: null,
    };
}

// actions

export function getCategories(token, store_id) {
    return async (dispatch, getState, { services: { dataSource } }) => {
        dispatch({ type: GET_CATEGORIES_REQUEST });
        try {
            const categories = await dataSource.getCategories(token, store_id);
            dispatch({
                type: GET_CATEGORIES_SUCCESS,
                payload: { categories },
            });
        } catch (error) {
            dispatch({
                type: GET_CATEGORIES_FAILURE,
                payload: { error },
            });
            const snack = { open: true, severity: 'error', message: 'Error fetching categories: ' + error };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
